window.klaroConfig = {
    elementID: 'klaro',
    storageMethod: 'cookie',
    storageName: 'klaro',
    htmlTexts: true,
    cookieExpiresAfterDays: 365,
    privacyPolicy: '/datenschutzerklaerung',
    default: false,
    mustConsent: false,
    acceptAll: true,
    hideDeclineAll: false,

    translations: {
        de: {
            bookingkit: {
                description: 'Online Buchungssystem für Touren, Aktivitäten & Attraktionen',
            },
            consentModal: {
                title: 'Informationen die wir speichern',
                description:
                    'Hier können Sie einsehen und anpassen, welche Information wir über Sie sammeln.',
            },
            consentNotice: {
                description: 'Wir schätzen Ihre Privatsphäre. Daher bitten wir Sie hiermit um Erlaubnis, die folgenden Technologien zu verwenden: <br />{purposes}. <br />Sie können Ihre Einwilligung später zu jederzeit ändern/widerrufen (Cookie-Einstellungen)',

            },
            inlineTracker: {
                description: 'Beispiel für ein Inline-Tracking Skript',
            },
            externalTracker: {
                description: 'Beispiel für ein externes Tracking Skript',
            },
            adsense: {
                description: 'Anzeigen von Werbeanzeigen (Beispiel)',
            },
            googleAnalytics: {
                description: 'Optimierung unserer Website (Anbieter: Google)',
            },
            camera: {
                description:
                    'Eine Überwachungskamera (nur ein Beispiel zu IMG-Tags)',
            },
            cloudflare: {
                description: 'Schutz gegen DDoS-Angriffe',
            },
            intercom: {
                description:
                    'Chat Widget & Sammeln von Besucherstatistiken (nur ein Beispiel)',
            },
            klaro: {
                description:
                    'Verwaltung von Cookies',
            },
            mouseflow: {
                description: 'Echtzeit-Benutzeranalyse (nur ein Beispiel)',
            },
            googleFonts: {
                description: 'Web-Schriftarten von Google gehostet',
            },
            purposes: {
                analytics: 'Besucherstatistik',
                security: 'Sicherheit',
                livechat: 'Live Chat',
                advertising: 'Anzeigen von Werbung',
                styling: 'Styling',
                convert: 'Vorlesefunktion für Text',
                video: 'Abspielen von Videos',
                recaptcha: 'Vermeidung von Spam',
                map: 'Kartenanzeige',
                fonts: 'Schriftdarstellung',
                cookie: 'Speicherung von Einstellungen dieser Anwendung',
                bookingkit: 'Online Buchungssystem',
            },
            reCaptcha: {
                description: 'Externes Tool zur Formularverarbeitung (Anbieter: Google)',
            },
            typekit: {
                description: 'Webschriften anzeigen (Anbieter: Adobe)',
            },
            vimeo: {
                description: 'Externer Videoplayer (Anbieter: Vimeo)',
            },
            googleMap: {
                description: 'Kartendarstellung (Anbieter: Google)',
            },
            close: "Speichern",
            acceptSelected: "Auswahl bestätigen",
            ok: "Allen zustimmen",
            decline: "Ablehnen",
            poweredBy: ' ',
        },
    },

    // This is a list of third-party apps that Klaro will manage for you.
    apps: [
        {
            name: 'klaro',
            title: 'Klaro! Consent Manager',
            purposes: ['cookie'],
            required: true,
        },
        {
            name: 'matomo',
            default: true,
            title: 'Matomo/Piwik',
            purposes: ['analytics'],
            cookies: [
                [/^_pk_.*$/, '/', 'klaro.kiprotect.com'],
                'piwik_ignore',
            ],
            required: false,
            optOut: false,
            onlyOnce: true,
        },
        {
            name: 'typekit',
            title: 'Adobe Typekit',
            purposes: ['fonts'],
            required: false,
            callback: function(consent, app) {
                console.log(
                    'User consent for app ' + app.name + ': consent=' + consent
                );
                var consentcheck = app.name + consent;
                if (consentcheck == "typekittrue") {
                    function loadjscssfile(filename, filetype){
                        if (filetype=="js"){ //if filename is a external JavaScript file
                            var fileref=document.createElement('script')
                            fileref.setAttribute("type","text/javascript")
                            fileref.setAttribute("src", filename)
                        }
                        else if (filetype=="css"){ //if filename is an external CSS file
                            var fileref=document.createElement("link")
                            fileref.setAttribute("rel", "stylesheet")
                            fileref.setAttribute("type", "text/css")
                            fileref.setAttribute("href", filename)
                        }
                        if (typeof fileref!="undefined")
                            document.getElementsByTagName("head")[0].appendChild(fileref)
                    }
                    loadjscssfile("https://use.typekit.net/cun5ruy.css", "css");
                }
            },
        },
        {
            name: 'readspeaker',
            default: false,
            title: 'Readspeaker',
            purposes: ['convert'],
            required: false,
            optOut: false,
            onlyOnce: true,
        },
        {
            name: 'googleMap',
            title: 'Google Maps',
            purposes: ['map'],
            required: false,
            callback: function (consent, app) {
                console.log(
                    'User consent for app ' + app.name + ': consent=' + consent
                );
                var consentcheck = app.name + consent;

                if (consentcheck == "googleMapfalse") {
                    $(".map_canvas").each(function () {
                        $(this).parent().addClass("blocked").prepend("<p class='message'><strong>Diese Karte kann auf Grund Ihrer Datenschutz-Einstellungen nicht geladen werden.</strong><br /><button class='pref' onclick='return klaro.show();'>Einstellungen ändern</button><button class='pref' onclick=\"klaro.getManager().updateConsent('googleMap', true);klaro.getManager().saveAndApplyConsents();\">Einstellungen sofort ändern und Karte laden</button></p>");
                    });
                } else {
                    $(".map_canvas").each(function () {
                        $(this).parent().removeClass("blocked").find(".message").remove();
                    });
                }
            },
        },
        {
            name: 'vimeo',
            title: 'vimeo',
            purposes: ['video'],
            required: false,
            callback: function(consent, app) {

                var consentcheck = app.name + consent;

                if (consentcheck == "vimeofalse") {
                    $(".vimeo iframe").each(function() {
                        var extlink = $(this).attr("data-src");
                        $(this).parent().addClass("blocked").prepend("<p class='message'><strong>Dieses Video kann auf Grund Ihrer Datenschutz-Einstellungen nicht abgespielt werden.</strong><br /><button class='pref' onclick='return klaro.show();'>Einstellungen ändern</button><a class='extlink' target='_blank' href='" + extlink + "'><br />Video direkt bei vimeo öffnen.</a></p>");
                    });
                } else {
                    $(".vimeo iframe").each(function() {
                        $(this).parent().removeClass("blocked").find(".message").remove();
                        $(this).addClass('lazyload');
                    });
                }
            },
        },
        {
            name: 'youtube',
            title: 'YouTube',
            purposes: ['video'],
            required: false,
            // callback: function(consent, app) {
            //
            //     var consentcheck = app.name + consent;
            //
            //     if (consentcheck == "youtubefalse") {
            //         $(".youtube iframe").each(function() {
            //             var extlink = $(this).attr("data-src");
            //             $(this).parent().addClass("blocked").prepend("<p class='message'><strong>Dieses Video kann auf Grund Ihrer Datenschutz-Einstellungen nicht abgespielt werden.</strong><br /><button class='pref' onclick='return klaro.show();'>Einstellungen ändern</button><a class='extlink' target='_blank' href='" + extlink + "'><br />Video direkt bei YouTube öffnen.</a></p>");
            //         });
            //     } else {
            //         $(".youtube iframe").each(function() {
            //             $(this).parent().removeClass("blocked").find(".message").remove();
            //             $(this).addClass('lazyload');
            //         });
            //     }
            // },
        },
        {
            name: 'bookingkit',
            title: 'BookingKit',
            purposes: ['bookingkit'],
            required: false,
        },

    ],
};